<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-text-field
            flat
            dense
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="salePayments" dense :search="search">
        <template v-slot:item.no="{ item }">
          {{ salePayments.map(x => x).indexOf(item) + 1 }}
        </template>
        <template v-slot:item.payDate="{ item }">
          {{ formatDate(item.payDate) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn small color="success" @click.stop="approval(item.id)"
            ><v-icon>mdi-check-outline</v-icon></v-btn
          >
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <dialog-approval-sale-payment
      :dialog="dialog"
      :form="salePayment"
      :listBank="listBank"
      @approved="approved"
      @close="close"
    ></dialog-approval-sale-payment>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import DialogApprovalSalePayment from "@/components/DialogApprovalSalePayment.vue";
export default {
  name: "sale-payment-approval",
  components: {
    DialogApprovalSalePayment,
  },
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "Voucher#",
        align: "center",
        sortable: false,
        value: "voucherNumber",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Pay Date",
        value: "payDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Customer",
        value: "customerName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Method",
        value: "method",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Invoice#",
        value: "invoiceNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    dialog: false,
    salePayment: {},
    listBank: [],
    search: "",
  }),

  computed: {
    ...mapState("salePayment", ["salePayments"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getBanks(), this.$store.dispatch("salePayment/getApproval")])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    approval(id) {
      this.getSalePayment(id);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async getSalePayment(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("salePayment/getById", id)
        .then(response => {
          this.salePayment = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getBanks() {
      await this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBank = response.data;
      });
    },
    async approved(params) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("salePayment/approved", params)
        .then(() => {
          this.initialize();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
